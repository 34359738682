import { useCallback } from 'react'

interface CardsClickOutEvent {
    category: string
    event: 'cards-click-out'
    hasPassword: boolean
}

interface ShowDetailsEvent {
    event: 'show_details'
    trigger: string
}

type SupportedEventData = CardsClickOutEvent | ShowDetailsEvent

const useGoogleTagManager = () => {
    const dataLayer =
        typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined'
            ? window.dataLayer
            : []

    const send = useCallback((eventData: SupportedEventData) => {
        if (dataLayer != null) {
            dataLayer.push(eventData)
        } else {
            console.log('[GTM Event]', eventData)
        }
    }, [])

    return send
}

export default useGoogleTagManager
