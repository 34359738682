export enum LoggerKey {
    AlternativeProductsErrorOffersRedirect = 'alternativeProductsErrorOffersRedirect',
    AlternativeProductsErrorView = 'alternativeProductsErrorView',
    AuthControllerReauth = 'authControllerReauth',
    MatchFactorRankingError = 'matchFactorRankingError',
    NotFoundPageView = 'notFoundPageView',
    PostCreditCardsEmailTriggerError = 'postCreditCardsEmailTriggerError',
    ScoredTableErrorOffersRedirect = 'scoredTableErrorOffersRedirect',
    ScoredTableErrorView = 'scoredTableErrorView',
    UnscoredAlternativeProductsView = 'unscoredAlternativeProductsView',
}
