import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'

import { getAppConfig } from '@utils/getAppConfig'

const DevToolBanner = () => {
    const appConfig = getAppConfig()

    const isViewingProd =
        appConfig.CLICK_OUT_URL.includes('https://www.totallymoney.com') ??
        false

    if (!isViewingProd) {
        return null
    }

    return (
        <Box
            display="flex"
            backgroundColor="alertError120"
            width="100%"
            height="50px"
            justifyContent="center"
        >
            <Box display="flex" justifyContent="center" alignItems="center">
                <Text color="white">
                    <strong>Warning:</strong> you are using production
                    environment variables, avoid clicking out.
                </Text>
            </Box>
        </Box>
    )
}

export default DevToolBanner
