import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import { sortCardsBySortKey } from '@utils/sortCardsBySortKey'

import {
    categorySortKeyComparatorKeys,
    defaultSortKeys,
} from './categorySortKeyComparatorKeys'

export type ScoredCreditCardProductDownsell = ScoredCreditCardProduct & {
    __downsell: true
    __isFirstDownsellCard?: boolean
}

const DOWNSELL_CARD_FLAG = '__downsell'

export const getIsDownsellCreditCard = (
    card: any
): card is ScoredCreditCardProductDownsell => {
    return card.hasOwnProperty(DOWNSELL_CARD_FLAG)
}

export const getShowDownsellCreditCards = (
    scoredCategories: ScoredCreditCardProductsCategory[]
): boolean => {
    if (scoredCategories == null) {
        return false
    }
    const balanceTransferCategoryCount =
        scoredCategories.find(
            category =>
                category.id === CreditCardProductCategory.BalanceTransfer
        )?.totalCreditCards ?? 0
    const poorCreditCategoryCount =
        scoredCategories.find(
            category => category.id === CreditCardProductCategory.PoorCredit
        )?.totalCreditCards ?? 0
    return balanceTransferCategoryCount <= 5 && poorCreditCategoryCount > 1
}

export const getDownsellCreditCards = (
    scoredCategories: ScoredCreditCardProductsCategory[],
    category: CreditCardProductCategory
): ScoredCreditCardProductDownsell[] | null => {
    const isDownsell =
        getShowDownsellCreditCards(scoredCategories) &&
        category === CreditCardProductCategory.BalanceTransfer
    if (!isDownsell) {
        return null
    }
    const downsellCategory = scoredCategories.find(
        item => item.id === CreditCardProductCategory.PoorCredit
    )
    if (downsellCategory == null) {
        return null
    }
    const sortedDownsellCards = sortCardsBySortKey({
        cards: downsellCategory.creditCards,
        category: CreditCardProductCategory.PoorCredit,
        sortKey: defaultSortKeys[CreditCardProductCategory.PoorCredit],
        categorySortKeyComparatorKeys,
    })
    return sortedDownsellCards.map((item, index) => ({
        ...item,
        [DOWNSELL_CARD_FLAG]: true,
        __isFirstDownsellCard: index === 0,
    }))
}
