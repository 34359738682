import { createContext, useState } from 'react'

import { Basin } from '@mediaingenuity/basin-sls/client'

interface BasinContextType {
    channel?: Basin
}

export const BasinContext = createContext<BasinContextType>({
    channel: undefined,
})

interface BasinProviderOptions {
    children: React.ReactElement
    debug?: boolean
    environment: 'production' | 'stage' | 'prod' | 'staging'
}

export const BasinProvider = ({
    children,
    debug,
    environment,
}: BasinProviderOptions) => {
    const [channel] = useState(() => {
        if (typeof window === 'undefined') {
            return {
                channel: {
                    send: () => Promise.resolve(),
                    updateMetadata: () => void 0,
                    uuids: {},
                    metadata: {},
                },
            } as unknown as { channel: Basin }
        }

        return {
            channel: new Basin({
                channel: 'TotallyMoney',
                source: 'CreditCards',
                environment: environment,
                debug:
                    debug ||
                    (typeof window != 'undefined'
                        ? window?.location?.search?.indexOf('debug') > -1
                        : false),
                metadata: {},
            }),
        }
    })

    return (
        <BasinContext.Provider value={channel}>
            {children}
        </BasinContext.Provider>
    )
}
