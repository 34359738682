import { useMutation } from '@tanstack/react-query'

interface Request<TData> {
    data: TData
    level: 'info' | 'error'
    message: string
}

export const useLoggerMutation = <TData>() => {
    return useMutation<void, void, Request<TData>>(body =>
        fetch(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/logger/`, {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        }).then(res => res.json())
    )
}
