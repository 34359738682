import { FlashMessageTypes } from '@appTypes/flashMessage'

import { CategoryPath } from '@config/categories'

import { getCategoryIdForPath } from './getCategoryIdForPath'

export interface SupportedQueryParameters {
    /**
     * The shorthand for adgroup
     */
    adg?: string
    /**
     * The bsft_mid that is provided by Blueshift.
     */
    bsft_mid?: string
    /**
     * A shorthand for campaign
     */
    c?: string
    /**
     * A shorthand for campaign
     */
    cam?: string
    /**
     * The credit card to display.
     */
    card?: string
    /**
     * The product code for the credit card to highlight
     * at the top of the table?
     */
    cardCode?: string
    /**
     * The product code for the credit card to highlight
     * at the top of the table.
     */
    cardMatch?: string
    /**
     * The category that should be displayed on the
     * table.
     */
    category?: string
    /**
     * The product name for the credit card...?
     */
    check?: string
    /**
     * The click source
     */
    csrc?: string
    /**
     * The customer id of the customer.
     */
    customerId?: string
    /**
     * Where the customer came from. i.e. WebApp or App.
     */
    customerOrigin?: string
    /**
     * The customer account type based on the
     * customer's data freshness. Typically indicated
     * by `account-initial` or `account-latest`
     */
    customerType?: string
    /**
     * Debug mode.
     */
    debug?: string
    /**
     * @deprecated Old functionality to retrieve
     * scores using the dormant score id.
     */
    dormantScoreId?: string
    /**
     * Display a card as sponsored using the card's
     * product code.
     */
    featured?: string
    /**
     * A string dictating which flashMessage to send from the offers refresh journey
     */
    flashMessage?: FlashMessageTypes
    /**
     * The google click id.
     */
    gclid?: string
    /**
     * Show the best match banner on the credit
     * card in the table.
     */
    isCardSpecific?: string
    /**
     * Unknown reason for usage.
     */
    isKnownCustomer?: string
    /**
     * A shorthand for keyword
     */
    k?: string
    /**
     * KBA scenario which is passed to the website
     * to show a particular banner.
     */
    kbaScenario?: string
    /**
     * A shorthand mediasource
     */
    m?: string
    /**
     * Is the customer new to the UK?
     */
    newToUk?: string
    /**
     * A shorthand mediasource
     */
    pid?: string
    /**
     * The referrer - i.e. loansdownsell
     */
    referrer?: string
    /**
     * The score id for the customer to retrieve
     * the customer's credit cards.
     */
    scoreId?: string
    /**
     * The score id for the customer to retrieve
     * the customer's credit cards.
     */
    scoreid?: string
    /**
     * The send id that is provided by Blueshift.
     */
    sendId?: string
    /**
     * Used for determining how many credit cards
     * to show on the table.
     */
    show?: string
    /**
     * Should show native app promotion?
     */
    showAppPromotion?: string
    /**
     * Used for sorting the credit cards, the key
     * is defined by `CategoryComparatorKey`
     */
    sortby?: string
    /**
     * The utm content
     */
    utm_content?: string
    /**
     * Where the customer come from. This was used when
     * redirecting the customer from the app to the web.
     */
    visitOrigin?: string
}

/**
 * Given the query parameters, we can create the app state
 * that can be used by the application.
 *
 * @param queryParameters - The query parameters from the URL
 * @returns The app state
 */
export function getAppStateFromQueryParameters(
    queryParameters: SupportedQueryParameters | null
) {
    if (queryParameters == null) {
        return {}
    }

    return {
        sortBy: queryParameters.sortby,

        numberOfCardsToShow:
            queryParameters.show != null
                ? parseInt(queryParameters.show, 10)
                : undefined,

        category: queryParameters.category as CategoryPath,
        categoryId: getCategoryIdForPath(queryParameters.category!),

        customerOrigin: queryParameters.customerOrigin,
        scoredCardUrl: queryParameters.card,
        cardMatchCode: queryParameters.cardMatch,
        cardCode: queryParameters.cardCode,
        checkCard: queryParameters.check,
        scoreId: queryParameters.scoreId ?? queryParameters.scoreid,
        dormantScoreId: queryParameters.dormantScoreId,
        isCardSpecific: queryParameters.isCardSpecific
            ? Boolean(queryParameters.isCardSpecific)
            : undefined,
        customerId: queryParameters.customerId,
        customerType: queryParameters.customerType,
        isKnownCustomer: queryParameters.isKnownCustomer,

        visitOrigin: queryParameters.visitOrigin ?? 'web',

        sendId: queryParameters.sendId ?? queryParameters.bsft_mid,
        googleClickId: queryParameters.gclid,
        keyword: queryParameters.k,
        mediaSource: queryParameters.m ?? queryParameters.pid,
        adgroup: queryParameters.adg,
        campaign: queryParameters.cam ?? queryParameters.c,
        clickSource: queryParameters.csrc,
        utmContent: queryParameters.utm_content,

        kbaScenario: queryParameters.kbaScenario,
        featured: queryParameters.featured,
        debug: queryParameters.debug,
        newToUk: queryParameters.newToUk,
        referrer: queryParameters.referrer,
        showAppPromotion: queryParameters.showAppPromotion,

        flashMessage: queryParameters.flashMessage,
    }
}

export type QueryParametersAppState = ReturnType<
    typeof getAppStateFromQueryParameters
>
