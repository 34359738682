import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import { ComparatorKey } from '@utils/sortCardsBySortKey'

export type CategoryComparatorKey =
    | 'introBtOffer'
    | 'introPurchaseOffer'
    | 'eligibility'
    | 'apr'
    | 'matchFactor'
    | 'transferFee'
    | 'cashbackEarned'
    | 'combinedIntroOffers'
    | 'rewardsEarned'
    | 'maxCreditLimit'
    | 'internationalCharges'

export const categorySortKeyComparatorKeys: {
    [TCategory in CreditCardProductCategory]: {
        [Key in CategoryComparatorKey]?: ComparatorKey[]
    }
} = {
    BestCards: {
        introBtOffer: [
            'bt-offer',
            'score',
            'purchase-offer',
            'apr',
            'display-order',
        ],
        introPurchaseOffer: [
            'purchase-offer',
            'score',
            'bt-offer',
            'apr',
            'display-order',
        ],
        eligibility: [
            'score',
            'bt-offer',
            'purchase-offer',
            'apr',
            'display-order',
        ],
        apr: ['apr', 'score', 'display-order'],
        matchFactor: [
            'match-factor',
            'bt-offer',
            'score',
            'purchase-offer',
            'apr',
            'display-order',
        ],
    },
    BalanceTransfer: {
        introBtOffer: [
            'bt-offer',
            'score',
            'transfer-fee',
            'apr',
            'display-order',
        ],
        transferFee: [
            'transfer-fee',
            'score',
            'bt-offer',
            'apr',
            'display-order',
        ],
        eligibility: [
            'score',
            'bt-offer',
            'transfer-fee',
            'apr',
            'display-order',
        ],
        apr: ['apr', 'score', 'display-order'],
        matchFactor: [
            'match-factor',
            'score',
            'bt-offer',
            'transfer-fee',
            'apr',
            'display-order',
        ],
    },
    Purchase: {
        introPurchaseOffer: ['purchase-offer', 'score', 'apr', 'display-order'],
        eligibility: ['score', 'purchase-offer', 'display-order'],
        apr: ['apr', 'score', 'display-order'],
        matchFactor: [
            'match-factor',
            'score',
            'purchase-offer',
            'apr',
            'display-order',
        ],
    },
    Cashback: {
        cashbackEarned: ['score', 'apr', 'display-order'],
        eligibility: ['score', 'apr', 'display-order'],
        apr: ['apr', 'score', 'display-order'],
        matchFactor: ['match-factor', 'score', 'apr', 'display-order'],
    },
    Combined: {
        introBtOffer: [
            'bt-offer',
            'score',
            'purchase-offer',
            'apr',
            'display-order',
        ],
        introPurchaseOffer: [
            'purchase-offer',
            'score',
            'bt-offer',
            'apr',
            'display-order',
        ],
        combinedIntroOffers: [
            'combined-introductory-offers',
            'score',
            'apr',
            'display-order',
        ],
        eligibility: [
            'score',
            'purchase-offer',
            'bt-offer',
            'apr',
            'display-order',
        ],
        apr: ['apr', 'score', 'display-order'],
        matchFactor: [
            'match-factor',
            'purchase-offer',
            'bt-offer',
            'score',
            'apr',
            'display-order',
        ],
    },
    Rewards: {
        rewardsEarned: ['score', 'apr', 'display-order'],
        eligibility: ['score', 'apr', 'display-order'],
        apr: ['apr', 'score', 'display-order'],
        matchFactor: ['match-factor', 'score', 'apr', 'display-order'],
    },
    PoorCredit: {
        apr: ['apr', 'score', 'bt-offer', 'purchase-offer', 'display-order'],
        maxCreditLimit: [
            'max-credit-limit',
            'score',
            'apr',
            'bt-offer',
            'purchase-offer',
            'display-order',
        ],
        eligibility: [
            'score',
            'apr',
            'bt-offer',
            'purchase-offer',
            'display-order',
        ],
        matchFactor: [
            'match-factor',
            'score',
            'apr',
            'bt-offer',
            'purchase-offer',
            'display-order',
        ],
    },
    Travel: {
        apr: [
            'apr',
            'score',
            'international-charges',
            'purchase-offer',
            'bt-offer',
            'display-order',
        ],
        internationalCharges: [
            'international-charges',
            'score',
            'apr',
            'purchase-offer',
            'bt-offer',
            'display-order',
        ],
        eligibility: [
            'score',
            'international-charges',
            'apr',
            'purchase-offer',
            'bt-offer',
            'display-order',
        ],
    },
}

export type CategorySortKeyComparatorKeys = typeof categorySortKeyComparatorKeys

export const sorterDisplayNames: Record<CategoryComparatorKey, string> = {
    introBtOffer: 'Introductory Balance Transfer Offer',
    introPurchaseOffer: 'Introductory Purchase Offer',
    eligibility: 'Eligibility',
    apr: 'APR',
    matchFactor: 'Match Factor',
    transferFee: 'Transfer Fee',
    cashbackEarned: 'Cashback Earned',
    combinedIntroOffers: 'Combined Introductory Offers',
    rewardsEarned: 'Rewards Earned',
    maxCreditLimit: 'Max Credit Limit',
    internationalCharges: 'International Charges',
} as const

export const defaultSortKeys: {
    [TCategory in CreditCardProductCategory]: keyof CategorySortKeyComparatorKeys[TCategory]
} = {
    BestCards: 'matchFactor',
    BalanceTransfer: 'matchFactor',
    Purchase: 'matchFactor',
    Cashback: 'matchFactor',
    Combined: 'matchFactor',
    Rewards: 'matchFactor',
    PoorCredit: 'matchFactor',
    Travel: 'eligibility',
}
