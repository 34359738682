import { useMemo } from 'react'

import { NextRouter, useRouter } from 'next/router'

import { categories } from '@config/categories'

function getPathValue(value: string) {
    if (value.includes('?')) {
        return value.split('?')?.[0]
    }
    if (value.includes('#')) {
        return value.split('#')?.[0]
    }
    return value
}

function getHasTrailingSlash(asPath: string) {
    const value = getPathValue(asPath)
    return value.endsWith('/')
}

function getNoTrailingSlashAsPath(asPath: string) {
    const value = getPathValue(asPath)

    return asPath.slice(0, value.lastIndexOf('/'))
}

export function getRouterParams(
    router: NextRouter,
    defaultParams?: Record<string, string>
) {
    if (
        typeof window === 'undefined' ||
        router.asPath == null ||
        router.pathname == null
    ) {
        return {}
    }

    const pathnameRoutes = router.pathname.split('/')

    let asPath = router.asPath.replace(`${router.basePath}/`, '/')

    if (getHasTrailingSlash(router.asPath)) {
        asPath = getNoTrailingSlashAsPath(asPath)
    }

    let asPathRoutes = asPath.split('/')
    const diffLength = pathnameRoutes.length - asPathRoutes.length

    /**
     * Support interpolating the first dynamic pathname by padding out
     * the `asPathRoutes` so we can apply the default paramter.
     */
    if (diffLength > 0) {
        const diff = pathnameRoutes.length - asPathRoutes.length
        asPathRoutes = [...new Array(diff).fill(undefined), ...asPathRoutes]
    }

    const routerObj = pathnameRoutes.reduce(
        (all, id, index) => {
            const value = asPathRoutes?.[index]

            if (id.startsWith('[') && id.endsWith(']')) {
                const idFormatted = id.replace('[', '').replace(']', '')

                if (value) {
                    all[idFormatted] = getPathValue(value)
                } else if (
                    defaultParams != null &&
                    idFormatted in defaultParams
                ) {
                    all[idFormatted] = defaultParams[idFormatted]
                }
            }

            return all
        },
        {} as Record<string, string>
    )

    return routerObj
}

const useRouterParams = (): Record<string, string> => {
    const router = useRouter()

    const routerParams = useMemo(() => {
        return getRouterParams(router, {
            category: categories['BestCards'].path,
        })
    }, [router.query, router.pathname, router.asPath, router.basePath])

    return routerParams
}

export default useRouterParams
