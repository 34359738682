import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'

import { MockQueryUnion } from '@api/mocks/get-scored-categories'

const getTestAccountName = (query?: MockQueryUnion) => {
    if (query === 'mock-scored') {
        return 'Scored Customer'
    }

    if (query === 'mock-altproducts') {
        return 'Alt Product Customer'
    }

    if (query === 'mock-bt-downsell') {
        return 'Downsell Customer'
    }
}

const DevToolBannerInfo = ({
    queryScoreId,
}: {
    queryScoreId?: MockQueryUnion
}) => {
    return (
        <Box
            display="flex"
            backgroundColor="alertInfo100"
            width="100%"
            height="50px"
            justifyContent="center"
        >
            <Box display="flex" justifyContent="center" alignItems="center">
                <Text weight="bold">
                    <strong>Info:</strong> {getTestAccountName(queryScoreId)}
                </Text>
            </Box>
        </Box>
    )
}

export default DevToolBannerInfo
