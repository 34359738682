import { FEATURE_FLAGS } from '@config/featureFlags'

const featureFlagKeys = Object.keys(FEATURE_FLAGS)

/**
 * This enables us to filter out any query parameters
 * we wouldn't want to persist.
 *
 * @param query - The query parameters
 * @returns The query parameters we'd want to persist
 */
export function getAllowedQueryParametersFromQuery(
    query: Record<string, string | string[] | undefined>
) {
    return Object.keys(query).reduce(
        (all, key) => {
            if (featureFlagKeys.includes(key)) {
                all[key] = query[key]
                return all
            }
            return all
        },
        {} as Record<string, string | string[] | undefined>
    )
}
