import { useEffect } from 'react'

import { setTags } from '@sentry/nextjs'
import { useRouter } from 'next/router'

import useAppQueryState from '@hooks/useAppQueryState'
import useFeatureFlagContext from '@hooks/useFeatureFlagContext'
import useLogEvents from '@hooks/useLogEvents'

import { getMarketingTrackingData } from '@utils/getMarketingTrackingData'
import { getPathnameFromAsPath } from '@utils/getPathnameFromAsPath'

const LogEvents = () => {
    const { logVisitEvent, logPageViewEvent } = useLogEvents()

    const { appQueryState } = useAppQueryState()
    const { asPath } = useRouter()
    const featureFlagContext = useFeatureFlagContext()

    const pathname = getPathnameFromAsPath(asPath)

    useEffect(() => {
        setTags({
            ...getMarketingTrackingData(appQueryState),
            scoreId: appQueryState.scoreId,
        })
    }, [])

    useEffect(() => {
        if (!featureFlagContext.isLoading) {
            logVisitEvent()
        }
    }, [featureFlagContext.isLoading])

    useEffect(() => {
        if (pathname != null && !featureFlagContext.isLoading) {
            logPageViewEvent({
                category: appQueryState.categoryId!,
            })
        }
    }, [pathname, featureFlagContext.isLoading])

    return null
}

export default LogEvents
